import { useMemo } from 'react';

import moment from 'moment';

interface Props {
  dateOnly?: boolean;

  value: string;

  withSecond?: boolean;
}

const FormatDateTime = ({ value, dateOnly = false, withSecond = false }: Props) => {
  const formatted = useMemo(() => {
    if (!value) {
      return '';
    }

    const m = moment(value);

    if (dateOnly) {
      return m.format('YYYY-MM-DD');
    }

    return m.format(withSecond ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD HH:mm');
  }, [value, dateOnly, withSecond]);

  return <>{formatted}</>;
};

export default FormatDateTime;
