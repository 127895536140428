import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { resolveShopName, Shop } from 'src/models/entity';

interface Props {
  shop: Shop;
}

const ShopName = ({ shop }: Props) => {
  const { i18n } = useTranslation();

  const shopName = useMemo(
    () => resolveShopName(i18n.language.startsWith('en'), shop.user_type_data),
    [shop, i18n.language]
  );

  return <>{shopName}</>;
};

export default ShopName;
