import {
  STAFF_REVIEW_WILL_FETCH_FIRST_PAGE,
  STAFF_REVIEW_DID_FETCH_FIRST_PAGE,
  STAFF_REVIEW_WILL_FETCH_NEXT_PAGE,
  STAFF_REVIEW_DID_FETCH_NEXT_PAGE,
} from '../../constants/constants';

const initialState = {
  subjectStaffId: '',

  /**
   * The last time when a list of news is fetched
   */
  firstPageLastFetchedAt: null,

  isFetchingFirstPage: false,

  isFetchingNextPage: false,

  hasFetchedAllReviews: false,

  reviews: [],
};

const staffReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case STAFF_REVIEW_WILL_FETCH_FIRST_PAGE:
      return {
        ...state,
        subjectStaffId: action.payload.staffId,
        firstPageLastFetchedAt: null,
        isFetchingFirstPage: true,
        hasFetchedAllReviews: false,
        reviews: [],
      };
    case STAFF_REVIEW_DID_FETCH_FIRST_PAGE:
      return {
        ...state,
        firstPageLastFetchedAt: new Date(),
        isFetchingFirstPage: false,

        // adopt remote items
        reviews: action.payload.reviews,
      };
    case STAFF_REVIEW_WILL_FETCH_NEXT_PAGE:
      return {
        ...state,
        isFetchingNextPage: true,
      };
    case STAFF_REVIEW_DID_FETCH_NEXT_PAGE:
      return {
        ...state,
        isFetchingNextPage: false,

        // append the next page of items to the end
        reviews: state.reviews.concat(action.payload.reviews),

        hasFetchedAllReviews: action.payload.hasFetchedAllReviews,
      };
    default:
      return {
        ...state,
      };
  }
};

export default staffReviewReducer;
