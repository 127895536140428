import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';


import { BookingReview } from 'src/models/entity';

import { useAuthenticated } from 'src/hooks/auth';

import {
  fetchFirstPageOfShopReviews,
  fetchNextPageOfShopReviews,
} from 'src/Redux/actions/features/shopReviewActions';

import { AppButton } from 'src/Components/Common/Button';
import Spinner from 'src/Components/Common/Spinner/Spinner';

import ReviewItem from './ReviewItem';


interface Props {
  // from parent
  shopId: string;

  // from mapStateToProps
  hasFetchedAllReviews: boolean;
  isFetchingFirstPageOfReviews: boolean;
  isFetchingNextPageOfReviews: boolean;
  reviews: BookingReview[];
  shopAvatar: string;

  // redux action
  fetchFirstPageOfShopReviews: (shopId: string) => void;
  fetchNextPageOfShopReviews: (
    shopId: string,
    since: string,
    skipping: string
  ) => void;
}

function ReviewList({
  shopId,

  hasFetchedAllReviews,
  isFetchingFirstPageOfReviews,
  isFetchingNextPageOfReviews,
  reviews,
  shopAvatar,

  fetchFirstPageOfShopReviews,
  fetchNextPageOfShopReviews,
}: Props) {
  const history = useHistory();

  const { t } = useTranslation();

  const { isShop, user } = useAuthenticated();

  useEffect(() => {
    fetchFirstPageOfShopReviews(shopId);

    window.scrollTo(0, 0);
  }, []);

  const isViewedByShopOwner = useMemo(() => {
    return isShop && shopId === user.id;
  }, [shopId, isShop, user.id]);

  const getNextPageOfReviews = () => {
    const lastReview = reviews[reviews.length - 1];

    fetchNextPageOfShopReviews(
      shopId,
      lastReview.created_at,
      lastReview.id,
    );
  };

  const redirectToView = (reviewId: string) => {
    if (shopId === user.id) {
      history.push(`/shop/review/${reviewId}`);
    } else {
      history.push(`/shop/${shopId}/review/${reviewId}`);
    }
  };

  return (
    <div className={isViewedByShopOwner ? '' : 'container'}>
      {isFetchingFirstPageOfReviews && <Spinner display="block" />}

      {reviews.map((review) => (
        <ReviewItem
          key={review.id}
          review={review}
          shopAvatar={shopAvatar}
          onRedirect={redirectToView}
        />
      ))}

      {reviews.length > 0 && !hasFetchedAllReviews && (
        <div className="mt-3">
          {isFetchingNextPageOfReviews ? (
            <Spinner display="block" />
          ) : (
            // Anchor to fetch more items
            <AppButton
              theme="primary"
              size="block"
              onClick={getNextPageOfReviews}
            >
              {t('shopPublic.overview.more')}
            </AppButton>
          )}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    hasFetchedAllReviews: state.shopReviewReducer.hasFetchedAllReviews,
    isFetchingFirstPageOfReviews: state.shopReviewReducer.isFetchingFirstPage,
    isFetchingNextPageOfReviews: state.shopReviewReducer.isFetchingNextPage,
    reviews: state.shopReviewReducer.reviews || [],
    shopAvatar: state.ShopReducer.shopData.avatar,
  };
}

export default connect(mapStateToProps, {
  fetchFirstPageOfShopReviews,
  fetchNextPageOfShopReviews,
})(ReviewList);
