import { BookingReview } from 'src/models/entity';

import { ListResponse } from 'src/models/api/response/ListResponse';

import { apiGet } from 'src/utils/api/api-utils';

export const fetchLatestBookingReviews = () =>
  apiGet<ListResponse<BookingReview>>('site/latest-booking-reviews').then(
    (response) => response.data
  );
