import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { BookingReview } from 'src/models/entity';

import { AppButton } from 'src/Components/Common/Button';
import OverallRatingRow from 'src/Components/Common/RatingStars/OverallRatingRow';
import { FormatDateTime, LocalizeDateTime } from 'src/Components/Common/DateTime';
import { LineBreakable } from 'src/Components/Common/Text';

import { CustomerName } from 'src/Components/Common/Customer';


interface Props {
  blockNum: number;
  isEditingReply: boolean;
  review: BookingReview;
  shopId: string;
  onChangeReply: (reviewId: string, newReply: string) => void;
  onEnableEditReply: (reviewId?: string) => void;
  onSubmitReply: (reviewId: string, reply: string) => void;
};

const ModerationItem = ({
  blockNum,
  isEditingReply,
  review,
  shopId,
  onChangeReply,
  onEnableEditReply,
  onSubmitReply,
}: Props) => {
  const { t } = useTranslation();

  return (
    <tr data-label-tr={`${t('block')} #${blockNum}`}>
      <td data-label={t('shopPrivate.reviewModer.date')}>
        <FormatDateTime value={review.created_at} dateOnly />
      </td>
      <td data-label={t('datetime.time.label')}>
        <LocalizeDateTime value={review.created_at} timeOnly />
      </td>
      <td data-label={t('shopPrivate.reviewModer.customer')}>
        {review.customer && <CustomerName customer={review.customer} />}
      </td>
      <td data-label={t('shopPrivate.reviewModer.rate')}>
        {review.review_average_rating && (
          <OverallRatingRow
            hideLabel
            rating={review.review_average_rating.overall_rating}
          />
        )}
      </td>
      <td data-label={t('shopPrivate.reviewModer.customerReview')}>
        <Link to={`/shop/${shopId}/review/${review.id}`} target="_blank">
          {review.title} <i className="fas fa-external-link-alt" />
        </Link>
        <LineBreakable>{review.review}</LineBreakable>
      </td>
      <td data-label={t('shopPrivate.reviewModer.yourReply')}>
        <div className="mb-1">
          {isEditingReply ? (
            <textarea
              className="form-control p-1"
              name="reply"
              value={review.reply || ''}
              onChange={(e) => onChangeReply(review.id, e.target.value)}
            />
          ) : (
            <LineBreakable>{review.reply}</LineBreakable>
          )}
        </div>

        {!isEditingReply ? (
          <AppButton
            size="small"
            theme="secondary"
            onClick={() => onEnableEditReply(review.id)}
          >
            <i className="fas fa-pen mr-1" />
            {t('shopPrivate.reviewModer.editReply')}
          </AppButton>
        ) : (
          <>
            <AppButton
              size="small"
              theme="success"
              onClick={() => onSubmitReply(review.id, review.reply)}
            >
              <i className="fas fa-save mr-1" />
              {t('action.save')}
            </AppButton>
            <AppButton
              size="small"
              theme="muted"
              onClick={() => onEnableEditReply(undefined)}
            >
              <i className="fas fa-save mr-1" />
              {t('action.cancel')}
            </AppButton>
          </>
        )}
      </td>
    </tr>
  );
};

export default ModerationItem;
