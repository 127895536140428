import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { BookingReview, Photo } from 'src/models/entity';

import { useAuthenticated } from 'src/hooks/auth';

import AspectImage from 'src/Components/Common/AspectImage/AspectImage';
import { CustomerAvatar, CustomerName } from 'src/Components/Common/Customer';
import { EllipsizeBlock } from 'src/Components/Common/Text';
import Localize from 'src/Components/Common/LanguageSwitch/Localize';
import RatingStars from 'src/Components/Common/RatingStars/RatingStars';

import styles from './SiteLatestReviewCard.module.css';


interface Props {
  // from parent:
  review: BookingReview;
}

const SiteLatestReviewCard = ({ review }: Props) => {
  const { user } = useAuthenticated();

  const [cardLinkTo, setCardLinkTo] = useState('');
  const [photos, setPhotos] = useState<Photo[]>([]);

  useEffect(() => {
    if (review.shop_id === user.id) {
      setCardLinkTo(`/shop/review/${review.id}`);
    } else {
      setCardLinkTo(`/shop/${review.shop_id}/review/${review.id}`);
    }
  }, [review.id]);

  useEffect(() => {
    setPhotos((review.photos || []).slice(0, 3)); // take first X items
  }, [review.photos]);

  return (
    <div className="col-lg-4">
      <div className={`px-3 py-4 ${styles.card}`}>
        {review.customer && (
          <Link
            to={`/customer/${review.customer.alias}`}
            className={styles.customerAvatar}
          >
            <CustomerAvatar customer={review.customer} />
          </Link>
        )}

        <Link to={cardLinkTo}>
          <RatingStars
            rating={review.review_average_rating.overall_rating}
            showNumeric
          />

          <p className={styles.shopName}>
            <Localize
              ch={review.shop_chinese_name || ''}
              en={review.shop_name || ''}
            />
          </p>

          <EllipsizeBlock
            className={styles.review}
            maxlength={100}
            text={review.review}
          />

          <div className={styles.footer}>
            {review.customer && (
              <CustomerName customer={review.customer} />
            )}
            <div className={styles.footRow}>
              {photos.map((photo) => (
                <div className={`px-1 ${styles.footCell}`} key={photo.id}>
                  <AspectImage aspectRatio="1:1">
                    <img src={photo.url} alt={photo.alt} />
                  </AspectImage>
                </div>
              ))}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SiteLatestReviewCard;
