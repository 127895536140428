import { useMemo } from 'react';

import moment from 'moment';

interface Props {
  timeOnly?: boolean;

  value: string;
}

const LocalizeDateTime = ({ timeOnly = false, value }: Props) => {
  const formatted = useMemo(() => {
    if (!value) {
      return '';
    }

    const m = moment(value);

    if (timeOnly) {
      return m.format('LT');
    }

    return m.format('lll');
  }, [timeOnly, value]);

  return <>{formatted}</>;
};

export default LocalizeDateTime;
