import { Day } from './Day';
import { User } from './User';

interface Address {
  coordinates: [string, string];
  string_address: string;
}

interface ShopData {
  name: string;
  chineseName: string;

  phone?: string;
  address?: Address;

  checkoutToken?: string;
}

export interface Shop extends User {
  remark: string;

  /**
   * TODO or boolean
   */
  working_now: number;

  schedule: Day[];

  /**
   * Expected an ISO 8601 date time string or falsy value
   */
  created_at: string;

  user_type_data?: ShopData;
}

export function resolveShopName(isEnglish: boolean, shopData?: ShopData) {
  return (
    (isEnglish ? shopData?.name : shopData?.chineseName) || shopData?.name || ''
  );
};
