import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { BookingReview } from 'src/models/entity';

import { fetchSiteLatestReview } from '../../../../Redux/actions/mainPage/siteLatestReviewActions';

import { AppButton } from 'src/Components/Common/Button';

import SiteLatestReviewCard from './SiteLatestReviewCard';

interface Props {
  // from mapStateToProps:
  isFetching: boolean;
  reviews: BookingReview[];

  // redux actions:
  fetchSiteLatestReview: () => void;
}

const SiteLatestReviews = ({
  // from mapStateToProps:
  isFetching,
  reviews,

  // redux actions:
  fetchSiteLatestReview,
}: Props) => {
  const { t } = useTranslation();

  const [isShowingAll, setShowingAll] = useState(false);

  const [persistedItems, setPersistedItems] = useState<BookingReview[]>([]);
  const [extraItems, setExtraItems] = useState<BookingReview[]>([]);

  useEffect(() => {
    fetchSiteLatestReview();
  }, []);

  useEffect(() => {
    setPersistedItems(reviews.slice(0, 3)); // take first X items

    setExtraItems(reviews.slice(3, 9));
  }, [reviews]);

  const toggleShowingAll = () => {
    setShowingAll(!isShowingAll);
  };

  return (
    <div className="container mb-5">
      <div className="section-title">
        <h2>{t('homePage.newReviews')}</h2>
      </div>

      <div className="row">
        {persistedItems.map((review) => (
          <SiteLatestReviewCard key={review.id} review={review} />
        ))}
      </div>

      {isShowingAll && (
        <div className="row">
          {extraItems.map((review) => (
            <SiteLatestReviewCard key={review.id} review={review} />
          ))}
        </div>
      )}

      {reviews.length > 3 && (
        <AppButton
          className="d-block my-3 mx-auto"
          shape="round"
          theme="primary"
          onClick={toggleShowingAll}
        >
          {isShowingAll ? t('homePage.showLessBtn') : t('homePage.showMoreBtn')}
        </AppButton>
      )}
    </div>
  );
};


function mapStateToProps(state: any) {
  return {
    isFetching: state.siteLatestReviewReducer.isFetchingSiteLatestReview,
    reviews: state.siteLatestReviewReducer.siteLatestReviews || [],
  };
}

export default connect(
  mapStateToProps,
  {
    fetchSiteLatestReview,
  }
)(SiteLatestReviews);
