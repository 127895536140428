import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';


import { normalizePhotosFromUrls } from '../../../../Functions/photoHelpers';

import AdvertisementView from 'src/Components/Common/Advertisement/AdvertisementView';
import notification from '../../../Common/notification';
import OverallRatingRow from '../../../Common/RatingStars/OverallRatingRow';
import RatingStars from '../../../Common/RatingStars/RatingStars';
import { ShopBadge } from '../../../Common/Shop';
import { Ellipsize, LineBreakable } from '../../../Common/Text';

import BookingPanel from '../Booking/BookingPanel';
import ShopNavigationButton from '../Navigation/ShopNavigationButton';


import LeftSideBar from './LeftSideBar';
import ShopMainNewsCardboard from './ShopMainNewsCardboard';
import ShopMainNewsSlider from './ShopMainNewsSlider';
import ShopRightMenu from './ShopRightMenu';
import ShopMainReviewCardboard from './ShopMainReviewCardboard';
import ShopMainReviewSlider from './ShopMainReviewSlider';
// import ShopMainRecommended from './ShopMainRecomended';
import ShopMainSlider from './ShopMainSlider';
import ShopPhotoCardboard from './ShopPhotoCardboard';
import ShopPhotoSlider from './ShopPhotoSlider';
import SocialShareBar from './SocialShareBar';


class ShopOverview extends Component {

  state = {
    shopMainPhotos: [],
    albumPhotos: [],

    aboutFull: false,
  };

  componentDidMount() {
    const { history, shop } = this.props;
    if (shop.active === 0) {
      notification(this.props.t('notifications.shopBlocked'));
      history.push('/');
      return;
    }

    // TODO Revise remote api, to deliver Photo[] to client
    const shopMainPhotos = normalizePhotosFromUrls(
      shop.general_media && Object.values(shop.general_media).map(val => val),
      shop.general_media_full && Object.values(shop.general_media_full).map(val => val)
      );

    // TODO Revise remote api, to deliver Photo[] to client
    const albumPhotos = normalizePhotosFromUrls(
      Object.keys(shop.galery).map(el => shop.galery[el]),
      Object.keys(shop.galery_full).map(el => shop.galery_full[el])
    );

    this.setState({
      shopMainPhotos,
      albumPhotos,
    });
  }

  showMore = (e) => {
    e.preventDefault();

    this.setState(prevSate => ({
      aboutFull: !prevSate.aboutFull,
    }));
  };

  render() {
    const {
      auth,
      match: { url },
      newsList,
      recommend,
      shop,
      t,
    } = this.props;
    const {
      aboutFull,

      shopMainPhotos,
      albumPhotos,
    } = this.state;

    const shopData = shop.user_type_data || {};
    const { about } = shopData;

    return (
      <>
        <Helmet>
          {shopData.metaTitle && <title>{shopData.metaTitle}</title>}
          {shopData.metaTitle && <meta property="og:title" id="og-title" content={shopData.metaTitle} />}
          {shopData.metaDescription && <meta name="description" property="og:description" id="og-description" content={shopData.metaDescription} />}
          {shopData.metaKeyword && <meta name="keywords" content={shopData.metaKeyword} />}
        </Helmet>

        <div className="container mt-1 mt-md-3 mt-lg-4">
          <div className="row">

            {/* Shop info, visible on screen <= md */}
            <div className="col-12 d-block d-lg-none my-2">
              <div className="border position-relative">
                <div className="shop-main__header">
                  {shop.user_type_data && (
                    <OverallRatingRow
                      className="px-1"
                      hideLabel
                      rating={shop.user_type_data.average_rating}
                    />
                  )}

                  {/* Shop label list */}
                  <div className="shop-labels">
                    {shop.label && shop.label.map(shopLabel => (
                      <span className="shop-label" key={shopLabel.id}>
                        {shopLabel.name}
                      </span>
                    ))}
                  </div>
                </div>

                {/* Slider of photos */}
                <ShopMainSlider
                  photos={shopMainPhotos}
                />
              </div>
            </div>

            {/* Left side bar (for screen >= lg), always visible */}
            <div className="col-12 col-lg-3">
              <LeftSideBar
                shop={shop}
                aboutFull={aboutFull}
                showMore={this.showMore}
              />
            </div>

            <div className="col-12 col-lg-7">

              {/* Shop photos, aka album, gallery, visible on screen <= md */}
              <ShopPhotoSlider
                className="d-block d-lg-none"
                photos={albumPhotos}
                url={url}
              />

              <div className="d-block d-lg-none">
                <BookingPanel shop={shop} />

                <ShopNavigationButton shop={shop} />
              </div>

              {/* Shop news, visible on screen <= md */}
              <ShopMainNewsSlider
                className="d-block d-lg-none"
                newsList={newsList}
                url={url}
                history={this.props.history}
              />

              {/* Advertisement, visible on screen <= md */}
              {/* The negative margins help display google ads with full width on mobile device */}
              <div className="mt-5 d-block d-lg-none" style={{ marginLeft: -15, marginRight: -15 }}>
                <AdvertisementView name="shop_page" />
              </div>

              {/* Latest reviews, visible on screen <= md */}
              <ShopMainReviewSlider
                className="d-block d-lg-none"
                shopId={shop.id}
              />

              {/* Shop info, visible on screen >= lg */}
              <div className="border d-none d-lg-block">
                <div className="shop-main__header">
                  <ShopBadge avatarSize="small" horizontal shop={shop} />

                  {/* Shop label list */}
                  <div className="shop-labels">
                    {shop.label && shop.label.map(shopLabel => (
                      <span className="shop-label" key={shopLabel.id}>
                        {shopLabel.name}
                      </span>
                    ))}
                  </div>
                </div>

                <ShopMainSlider
                  photos={shopMainPhotos}
                />

                <div className="p-3">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <SocialShareBar shop={shop} url={url} />
                    </div>
                    <div className="col-6">
                      <RatingStars rating={shop.user_type_data.average_rating} showNumeric />
                    </div>
                  </div>
                </div>
              </div>

              {/* Visible on screen >= lg */}
              <div className="d-none d-lg-block">
                <BookingPanel shop={shop} />
              </div>

              {/* Shop news, visible on screen >= lg */}
              <ShopMainNewsCardboard
                className="d-none d-lg-block"
                newsList={newsList}
                url={url}
                history={this.props.history}
              />

              <BookingPanel shop={shop} />

              {/* About Shop, visible on screen <= md */}
              <div className="shop-group d-block d-lg-none">
                <p className="theme-subtitle">{t('shopPublic.overview.leftBar.aboutShop')}</p>
                <div className="mt-2 py-1 px-2 border sidebar-content">
                  <LineBreakable className="about-shop">
                    {!about ? t('shopPublic.overview.nothingToShow') : aboutFull ? about : <Ellipsize text={about} maxlength={50} />}

                    {about &&
                      about.length > 50 && (
                        <a href="" onClick={this.showMore} className="more-link">
                          {aboutFull ? t('shopPublic.overview.less') : t('shopPublic.overview.more')}
                        </a>
                      )}
                  </LineBreakable>
                </div>
              </div>

{/* TODO Product? It was hidden in a long long time ago...
              <div className="shop-group d-none d-lg-block">
                <p className="theme-subtitle">{t('shopPublic.overview.recommended')}</p>
                <div className="row">
                  {recommend.length === 0 ? (
                    <div className="col-lg-12">
                      <p>{t('shopPublic.overview.nothingToShow')}</p>
                    </div>
                  ) : (
                    recommend.map((recommend, index) => (
                      <ShopMainRecommended
                        key={recommend.id}
                        photo={recommend.photo}
                        price={recommend.price}
                        name={recommend.name}
                        index={index}
                      />
                    ))
                  )}
                </div>
                <Link
                  to={`${url.slice(0, url.lastIndexOf('/'))}/product`}
                  className="more-link"
                >
                  {t('shopPublic.overview.more')}
                </Link>
              </div>
 */}

              {/* Shop photos, aka album, gallery, visible on screen >= lg */}
              <ShopPhotoCardboard
                className="d-none d-lg-block"
                photos={albumPhotos}
              />

              {/* Latest reviews, visible on screen >= lg */}
              <ShopMainReviewCardboard
                className="d-none d-lg-block"
                shopId={shop.id}
              />

              {/* The negative margins help display google ads with full width on mobile device */}
              <div className="mt-5" style={{ marginLeft: -15, marginRight: -15 }}>
                <AdvertisementView name="shop_page_bottom" delay={3000} />
              </div>
            </div>

            {/* Right side bar, visible on screen >= lg */}
            <div className="col-2 col-lg-2 d-none d-lg-block">
              <ShopRightMenu />
            </div>
          </div>
        </div>
      </>
    );
  }
}

ShopOverview.defaultProps = {
  auth: {
    user: {},
  },
  shop: {
    user_type_data: {},
    general_media: {},
    level: {},
    galery: [],
  },
  recommend: [],
};

function mapStateToProps(state) {
  const shop = state.ShopReducer.shopData;

  // adopt data from redux state only when the data is from the same shop
  const shopWithNewsList = state.shopNewsReducer.shopWithNewsList && state.shopNewsReducer.shopWithNewsList.id == shop.id ?
    state.shopNewsReducer.shopWithNewsList : {};

  return {
    auth: state.auth,
    newsList: shopWithNewsList.news || [],
    recommend: state.shopProductReducer.recommend,
    shop,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps
    )(ShopOverview)
  )
);
