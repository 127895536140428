import { useMemo } from 'react';

import { Customer, CustomerData } from 'src/models/entity';

const nameFromCustomerData = (customerData: CustomerData) =>
  (customerData?.first_name === customerData?.last_name
    ? customerData?.first_name
    : `${customerData?.first_name || ''} ${customerData?.last_name || ''}`
  ).trim();


interface Props {
  customer: Customer;
}

const CustomerName = ({ customer }: Props) => {
  const formatted = useMemo(() => {
    if (customer?.user_type_data) {
      const displayName = nameFromCustomerData(customer.user_type_data);
      if (displayName) return displayName;
    }

    return '';
  }, [customer.user_type_data]);

  return <>{formatted}</>;
};

export default CustomerName;
