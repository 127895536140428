import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';

import Spinner from 'src/Components/Common/Spinner/Spinner';

interface Props {
  className?: string;
  isBusy?: boolean;
  onClick: () => void;
}

const SaveIconButton = ({ className = '', isBusy = false, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      className={className}
      placement="top"
      title={`${t('action.save')}`}
    >
      <button
        className="btn btn-sm btn-outline-success mx-1"
        disabled={isBusy}
        type="button"
        onClick={onClick}
      >
        {isBusy ? <Spinner className="m-0" /> : <i className="fas fa-save" />}
      </button>
    </Tooltip>
  );
};

export default SaveIconButton;
