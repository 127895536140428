import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

interface Props {
  disabled?: boolean;
  isVisible: boolean;
  onClick: () => void;
}

const ToggleVisibilityButton = ({
  disabled = false,
  isVisible,
  onClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      disableHoverListener={disabled}
      placement="top"
      title={`${
        isVisible ? t('action.turnInvisible') : t('action.turnVisible')
      }`}
    >
      <button
        className="mx-1 btn btn-sm btn-light"
        disabled={disabled}
        type="button"
        onClick={onClick}
      >
        {isVisible ? (
          <i className="fas fa-eye"></i>
        ) : (
          <i className="fas fa-eye-slash" style={{ opacity: 0.4 }}></i>
        )}
      </button>
    </Tooltip>
  );
};

export default ToggleVisibilityButton;
