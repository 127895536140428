import { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';


import { BookingReview } from 'src/models/entity';

import { FormatDateTime } from 'src/Components/Common/DateTime';

import BookingReviewRatings from 'src/Components/Common/RatingStars/BookingReviewRatings';
import { CustomerBadge } from 'src/Components/Common/Customer';
import { ShopAvatar } from 'src/Components/Common/Shop';
import { LineBreakable } from 'src/Components/Common/Text';


interface Props {
  shopAvatar: string;
  review: BookingReview;
  onRedirect: (reviewId: string) => void;
}

function ReviewItem({
  shopAvatar,
  review,
  onRedirect,
}: Props) {
  const { t } = useTranslation();

  const history = useHistory();

  const linkTo = useMemo(() => `./review/${review.id}`, [review.id]);

  const redirectToReviewDetail = () => {
    history.push(linkTo);
  }

  return (
    <div className="review-container">
      <div className="row">
        <div className="col-xl-2">
          {review.customer && <CustomerBadge customer={review.customer} />}
        </div>

        <div className="col-xl-7" onClick={redirectToReviewDetail}>
          <h2>
            <Link
              className="review-title"
              to={linkTo}
              onClick={(e) => e.stopPropagation()}
            >
              {review.title}
            </Link>
          </h2>
          <p className="review-info">
            <FormatDateTime value={review.created_at} />
          </p>

          <LineBreakable className="review-content">
            {review.review}
          </LineBreakable>

          <div className="sharing-photo">
            {review.photos?.map((photo) => (
              <div className="sharing-photo__item" key={photo.id}>
                <div className="news-prev-img">
                  <img alt="" src={photo.url} />
                </div>
              </div>
            ))}
          </div>

          {review.reply && (
            <div className="review-answer">
              <p className="review-answer__title">
                {t('shopPublic.review.shopAnswer')}
              </p>
              <div className="answer__img">
                {shopAvatar && <ShopAvatar src={shopAvatar} />}
              </div>
              <div onClick={() => onRedirect(review.id)}>
                <LineBreakable className="review-answer__text">
                  {review.reply}
                </LineBreakable>
              </div>
            </div>
          )}
        </div>

        <div className="col-xl-3">
          <BookingReviewRatings ratings={review.review_average_rating} />
        </div>
      </div>
    </div>
  );
};

export default ReviewItem;
