import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Customer } from 'src/models/entity';

import CustomerAvatar from './CustomerAvatar';
import CustomerName from './CustomerName';

import styles from './CustomerBadge.module.css';

interface Props {
  avatarOnly?: boolean;
  avatarSize?: 'normal' | 'small' | 'tiny';
  className?: string;
  horizontal?: boolean;
  customer: Customer;
}

const CustomerBadge = ({
  avatarSize,
  className,
  customer,
  horizontal,
}: Props) => {
  const customerProfileUrl = useMemo(() => {
    const customerAlias = customer?.alias;
    return customerAlias ? `/customer/${customerAlias}` : '';
  }, [customer]);

  const avatarUrl = useMemo(() => {
    return customer?.avatar || '';
  }, [customer]);

  const customerLevelName = useMemo(
    () => customer?.customerLevel?.level_name || '',
    [customer]
  );

  const handleClickLink = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`${styles.root} ${
        horizontal ? styles.horizontal : ''
      } ${className}`}
    >
      <div
        className={`${styles.avatar} ${
          avatarSize === 'small'
            ? styles.smallAvatar
            : avatarSize === 'tiny'
            ? styles.tinyAvatar
            : ''
        }`}
      >
        {customerProfileUrl ? (
          <Link to={customerProfileUrl} onClick={handleClickLink}>
            <CustomerAvatar src={avatarUrl} />
          </Link>
        ) : (
          <CustomerAvatar src={avatarUrl} />
        )}
      </div>
      <div className={styles.name}>
        {customerProfileUrl ? (
          <Link to={customerProfileUrl} onClick={handleClickLink}>
            <CustomerName customer={customer} />
          </Link>
        ) : (
          <CustomerName customer={customer} />
        )}

        {customerLevelName && (
          <div className={styles.level}>
            <i className="fas fa-arrow-circle-up pr-1" />
            {customerLevelName}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerBadge;
