import { Customer } from './Customer';
import { Photo } from './Photo';
import { Shop } from './Shop';

export enum BookingReviewStatus {
  New = 'new',
  Approved = 'approved',
  Rejected = 'rejected',
  Edited = 'edited',
}

export interface BookingReviewRating {
  environment: number;
  skill: number;
  attitude: number;
  idea: number;
  overall_rating: number;
}

export interface BookingReview {
  id: string;

  booking_id: string;

  /**
   * User ID of the customer
   */
  user_id: string;

  /**
   * Text left by customer
   */
  title: string;

  /**
   * Text left by customer
   */
  review: string;

  /**
   * Response left by shop
   */
  reply: string;

  /**
   * A message to be delivered to customer, when the review is rejected by site admin.
   */
  why_rejected?: string;

  /**
   * Comment by site admin, only visible by site admin.
   */
  admin_comment?: string;

  visible: boolean;

  status_review: BookingReviewStatus;
  review_average_rating: BookingReviewRating;

  customer?: Customer;

  shop_id?: string;

  shop?: Shop;

  /**
   * TODO
   * @deprecated
   */
  shop_chinese_name?: string;

  /**
   * TODO
   * @deprecated
   */
  shop_name?: string;

  photos?: Photo[];

  /**
   * Expected an ISO 8601 date time string or falsy value
   */
  created_at: string;
}
