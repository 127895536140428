import {
  ADMIN_REVIEWS_DID_FETCH,
  ADMIN_REVIEW_DID_DELETE,
  ADMIN_REVIEW_DID_UPDATE,
} from '../../constants/constants';


const initialState = {
  reviews: [],
  reviewPagination: {
    currentPage: 1,
    lastPage: 1,
    total: 0,
  },
};


const adminReviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_REVIEWS_DID_FETCH:
      return {
        ...state,
        reviews: action.payload.reviews,
        reviewPagination: action.payload.reviewPagination,
      };
    case ADMIN_REVIEW_DID_UPDATE:
      return {
        ...state,
        reviews: state.reviews.map((review) =>
          review.id === action.payload.review.id
            ? action.payload.review
            : review
        ),
      };
    case ADMIN_REVIEW_DID_DELETE:
      return {
        ...state,
        reviews: state.reviews.filter(
          (review) => review.id != action.payload.reviewId
        ),
      };
    default:
      return {
        ...state,
      };
  }
};

export default adminReviewsReducer;
