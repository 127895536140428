import {
  DELETE_STAFF,
  STAFF_DID_GET,
  GET_SHOP_STUFF,
  SHOP_STAFF_LIST_DID_FETCH,
  SHOP_STAFF_LIST_WILL_FETCH,
  SET_STAFF_RIGHTS,
} from '../../constants/constants';

const initialState = {
  /***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** *****
   * For response from API [GET] `/get-shop-in-staff?shop_id=${shopId}&current_page=${currentPage}`:
   */

  shopStaff: {
    current_page: 1,
    last_page: 1,
    data: [],
  },


  /***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** *****
   * For response from API [GET] `/get-shop-staff?shop_id={shopId}`:
   */

  /**
   * Whether the client is fetching a list of staff
   */
  isFetchingShopStaffDataList: false,

  /**
   * The last time when a list of staff is fetched
   */
  shopStaffDataListLastFetchedAt: null,

  shopId: '',

  shopStaffDataList: [],

  requestedStaffList: [],


  /***** ***** ***** ***** ***** ***** ***** ***** ***** ***** ***** *****
   * For response from API [GET] `/get-staff?staff_id=${staffId}`:
   */

  staff: {},


  deleteStaffData: [],
};

const GetStuffInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOP_STAFF_LIST_WILL_FETCH:
      return {
        ...state,
        isFetchingShopStaffDataList: true,
        shopStaffDataList: state.shopId == action.payload.shopId ? state.shopStaffDataList : [], // (same shop) ? (adopt staff data list from prev state) : (empty list)
        requestedStaffList: state.shopId == action.payload.shopId ? state.requestedStaffList : [], // (same shop) ? (adopt staff list from prev state) : (empty list)
        shopId: action.payload.shopId,
      };
    case SHOP_STAFF_LIST_DID_FETCH:
      return {
        ...state,
        isFetchingShopStaffDataList: false,
        shopStaffDataListLastFetchedAt: new Date(),
        shopId: action.payload.shopId,
        shopStaffDataList: action.payload.responseData.staff_in_shop,
        requestedStaffList: action.payload.responseData.staff_requests || [],
      };
    case GET_SHOP_STUFF:
      return {
        ...state,
        shopStaff: action.payload,
      };
    case DELETE_STAFF:
      return {
        ...state,
        deleteStaffData: action.payload,
      };
    case STAFF_DID_GET:
      return {
        ...state,
        staff: action.payload,
      };
    case SET_STAFF_RIGHTS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default GetStuffInformationReducer;
