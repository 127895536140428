import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { useParams, withRouter } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';


import { BookingReview } from 'src/models/entity';

import { shopBookingReviewAPI } from 'src/utils/api';

import useErrorHandler from 'src/hooks/useErrorHandler';

import { useAuthenticated } from 'src/hooks/auth';

import AdvertisementView from 'src/Components/Common/Advertisement/AdvertisementView';
import BookingReviewRatings from 'src/Components/Common/RatingStars/BookingReviewRatings';
import { CustomerBadge } from 'src/Components/Common/Customer';
import { FormatDateTime } from 'src/Components/Common/DateTime';
import { LineBreakable } from 'src/Components/Common/Text';
import PhotoViewer from 'src/Components/Common/PhotoViewer/PhotoViewer';
import Spinner from 'src/Components/Common/Spinner/Spinner';
import { ShopAvatar } from 'src/Components/Common/Shop';


interface RouteParams {
  reviewId: string;
}

interface Props {
  // from parent
  shopId: string;

  // from redux store
  reviewFromStore?: BookingReview;
}

function ReviewDetail({ shopId, reviewFromStore }: Props) {
  const { t } = useTranslation();

  const { promptErrorMessageFromAxiosError } = useErrorHandler();

  const { reviewId } = useParams<RouteParams>();

  const { isShop, user } = useAuthenticated();

  const isViewedByShopOwner = useMemo(() => {
    return isShop && shopId === user.id;
  }, [shopId, isShop, user.id]);

  const [review, setReview] = useState<BookingReview>();
  const [isFetchingItem, setFetchingItem] = useState(false);

  const [pageTitle, setPageTitle] = useState('');
  const [pageDescription, setPageDescription] = useState('');

  const [isPhotoViewerOpened, setPhotoViewerOpened] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);

  useEffect(() => {
    if (reviewFromStore) {
      // The desired data exists in redux store
      // => adopt such store data
      setReview(reviewFromStore);
    } else {
      // The desired data cannot be found from redux store
      // => fetch remote data
      fetchRemoteReview();
    }
  }, [reviewFromStore]);

  useEffect(() => {
    if (review) {
      const shopChineseName = review.shop_chinese_name;
      const shopEnglishName = review.shop_name;

      const resolvedShopName =
        shopChineseName === shopEnglishName
          ? shopEnglishName
          : `${shopChineseName} ${shopEnglishName}`;

      setPageTitle(
        `客人給 ${resolvedShopName} 作出了評價：${review.title} ${review.review}`
      );
      setPageDescription(review.review);
    } else {
      setPageTitle('');
      setPageDescription('');
    }
  }, [review]);

  const fetchRemoteReview = () => {
    if (!shopId) {
      return;
    }

    setFetchingItem(true);

    shopBookingReviewAPI
      .getPublishedReview(shopId, reviewId)
      .then((remoteReview) => {
        setReview(remoteReview);
      })
      .catch((e) => {
        // TODO Upgrade axios
        // if (e instanceof AxiosError) {
        promptErrorMessageFromAxiosError(e);
        // }
      })
      .finally(() => {
        setFetchingItem(false);
      });
  };

  const openPhotoViewer = (activePhotoIndex: number) => {
    setPhotoViewerOpened(true);
    setActivePhotoIndex(activePhotoIndex);
  };

  const closePhotoViewer = () => {
    setPhotoViewerOpened(false);
  };

  return (
    <>
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        {pageTitle && (
          <meta property="og:title" id="og-title" content={pageTitle} />
        )}

        {pageDescription && (
          <meta
            name="description"
            property="og:description"
            id="og-description"
            content={pageDescription}
          />
        )}
      </Helmet>

      {isPhotoViewerOpened && review?.photos && (
        <PhotoViewer
          photos={review.photos}
          initialIndex={activePhotoIndex}
          onClose={closePhotoViewer}
        />
      )}

      <div className={isViewedByShopOwner ? '' : 'container'}>
        <div className="review-container">
          {isFetchingItem && <Spinner display="block" />}

          {review && (
            <div className="row">
              <div className="col-lg-2">
                {review.customer && (
                  <CustomerBadge customer={review.customer} />
                )}
              </div>

              <div className="col-lg-7">
                <h2 className="review-title">{review.title}</h2>
                <p className="review-info">
                  <FormatDateTime value={review.created_at} />
                </p>

                <LineBreakable className="review-content">
                  {review.review}
                </LineBreakable>

                {review.photos?.map((photo, index) => (
                  <div
                    className="review-baner temp-cover temp-cover--no-border"
                    key={index}
                  >
                    <img
                      alt=""
                      src={photo.url}
                      style={{ cursor: 'pointer' }}
                      onClick={() => openPhotoViewer(index)}
                    />
                  </div>
                ))}

                {review.reply && (
                  <div className="review-answer">
                    <p className="review-answer__title">
                      {t('shopPublic.review.shopAnswer')}
                    </p>
                    <div className="answer__img">
                      {review.shop && <ShopAvatar src={review.shop.avatar} />}
                    </div>
                    <LineBreakable className="review-answer__text">
                      {review.reply}
                    </LineBreakable>

                    {/* For visually distinguishing shop's reply and customer's review rating */}
                    <hr className="d-block d-lg-none" />
                  </div>
                )}
              </div>

              <div className="col-lg-3">
                {review.review_average_rating && (
                  <BookingReviewRatings
                    ratings={review.review_average_rating}
                  />
                )}

                <div className="my-5 d-none d-lg-block">
                  <AdvertisementView name="review_page" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-3 d-lg-none">
        <AdvertisementView name="review_page" />
      </div>
    </>
  );
}

function mapStateToProps(state: any, props: any) {
  const shopId = props.shopId;
  const reviewId = props.match.params.reviewId;

  // Try to retrieve from redux store
  const review = state.shopReviewReducer.reviews.find(
    (review: BookingReview) => review.id == reviewId && review.shop_id == shopId
  );

  return {
    reviewFromStore: review,
  };
}

export default withRouter(
  connect(mapStateToProps)(ReviewDetail)
);
