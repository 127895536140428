import { BookingReview } from 'src/models/entity';

import { GetResponse } from 'src/models/api/response/GetResponse';
import {
  ListResponse,
  PaginatedListResponse,
} from 'src/models/api/response/ListResponse';

import { apiGet, apiPut } from '../api-utils';


/**
 * Get a list of paginated booking reviews under a shop
 * @param shopId
 * @param limit
 * @param since Expected a date-time string
 * @param skipping Expected a Booking Review ID
 */
export const getShopPublishedReviews = (
  shopId: string,
  limit?: number,
  since?: string,
  skipping?: string
) =>
  apiGet<ListResponse<BookingReview>>(`shop/${shopId}/reviews`, {
    limit,
    since,
    skipping,
  }).then((response) => response.data);

/**
 * Get a piece of booking review under a shop
 * @param shopId
 * @param reviewId
 * @returns
 */
export const getPublishedReview = (shopId: string, reviewId: string) =>
  apiGet<GetResponse<BookingReview>>(`shop/${shopId}/review/${reviewId}`).then(
    (response) => response.data
  );

export const getShopReviewsForModeration = (page?: number) =>
  apiGet<PaginatedListResponse<BookingReview>>(`review-moderation`, {
    page,
  });

export const leaveReplyToReview = (reviewId: string, reply: string) =>
  apiPut<GetResponse<BookingReview>>(`review/${reviewId}/reply`, {
    reply,
  }).then((response) => response.data);
