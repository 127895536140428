import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';


import { BookingReview } from 'src/models/entity';

import { shopBookingReviewAPI } from 'src/utils/api';

import { useAuthenticated } from 'src/hooks/auth';
import useErrorHandler from 'src/hooks/useErrorHandler';


import Breadcrumb from 'src/Components/Common/Breadcrumb/Breadcrumb';
import { DataTableStatus, TableHeaderCell } from 'src/Components/Common/DataTable';
import notification from 'src/Components/Common/notification';
import Pagination from 'src/Components/Common/Pagination';

import ModerationItem from './ModerationItem';


function ReviewModeration() {
  const { t, i18n } = useTranslation();

  const { promptErrorMessageFromAxiosError } = useErrorHandler();

  const { user } = useAuthenticated();

  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [reviews, setReviews] = useState<BookingReview[]>([]);
  const [activeReviewId, setActiveReviewId] = useState<string>();

  const routes = useMemo(() => [{
    title: t('shopPrivate.reviewModer.reviewModer'),
    path: window.location.pathname,
   }], [i18n.language]);

  useEffect(() => {
    fetchPageOfReviewForModeration(1);
  }, []);

  const fetchPageOfReviewForModeration = (page: number) => {
    setLoading(true);

    shopBookingReviewAPI
      .getShopReviewsForModeration(page)
      .then((response) => {
        setCurrentPage(response.meta.current_page);
        setLastPage(response.meta.last_page);
        setReviews(response.data);
        setLoading(false);
      })
      .catch((e) => {
        // TODO Upgrade axios
        // if (e instanceof AxiosError) {
        promptErrorMessageFromAxiosError(e);
        // }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleEditReply = (nextActiveReviewId?: string) => {
    setActiveReviewId(nextActiveReviewId);
  };

  const handleChangeReply = (reviewId: string, newReply: string) => {
    setReviews(
      reviews.map((review) => {
        if (review.id === reviewId) {
          review.reply = newReply;
        }
        return review;
      })
    );
  };

  const handlePageChange = (selected: number) => {
    fetchPageOfReviewForModeration(selected + 1);
  };

  const handleSubmitReply = (reviewId: string, reply: string) => {
    shopBookingReviewAPI
      .leaveReplyToReview(reviewId, reply)
      .then(() => {
        setActiveReviewId(undefined);
        notification(t('notifications.changesSaved'));
      })
      .catch((e) => {
        // TODO Upgrade axios
        // if (e instanceof AxiosError) {
        promptErrorMessageFromAxiosError(e);
        // }
      });


  };

  return (
    <div className="mainPanel">
      <h2 className="categoryTitle">
        <i className="fas fa-star" aria-hidden="true" />
        {t('shopPrivate.reviewModer.reviewModer')}
      </h2>

      <Breadcrumb routes={routes} buttonExist />

      <div className="main-container">
        <div className="page-head">
          <h2 className="page-title">
            {t('shopPrivate.reviewModer.reviewModer')}
          </h2>
        </div>
        <div className="main-container__content">
          <div className="tableWrap">
            <table className="adminTable">
              <colgroup>
                <col style={{ minWidth: 120, width: '10%' }} />
                <col style={{ minWidth: 120, width: '10%' }} />
                <col style={{ minWidth: 100, width: '12%' }} />
                <col style={{ minWidth: 100, width: 100 }} />
                <col style={{ minWidth: 200 }} />
                <col style={{ minWidth: 200 }} />
              </colgroup>
              <thead>
                <tr>
                  <TableHeaderCell>
                    {t('shopPrivate.reviewModer.date')}
                  </TableHeaderCell>
                  <TableHeaderCell>{t('datetime.time.label')}</TableHeaderCell>
                  <TableHeaderCell>
                    {t('shopPrivate.reviewModer.customer')}
                  </TableHeaderCell>
                  <TableHeaderCell>
                    {t('shopPrivate.reviewModer.rate')}
                  </TableHeaderCell>
                  <TableHeaderCell>
                    {t('shopPrivate.reviewModer.customerReview')}
                  </TableHeaderCell>
                  <TableHeaderCell>
                    {t('shopPrivate.reviewModer.yourReply')}
                  </TableHeaderCell>
                </tr>
              </thead>
              <tbody>
                {reviews.map((review, index) => (
                  <ModerationItem
                    blockNum={index + 1}
                    isEditingReply={activeReviewId === review.id}
                    key={review.id}
                    review={review}
                    shopId={user.id}
                    onChangeReply={handleChangeReply}
                    onEnableEditReply={toggleEditReply}
                    onSubmitReply={handleSubmitReply}
                  />
                ))}
              </tbody>
            </table>

            <DataTableStatus isLoading={isLoading} itemCount={reviews.length} />
          </div>
        </div>

        {lastPage > 1 && (
          <Pagination
            currentPage={currentPage}
            lastPage={lastPage}
            pageChange={(e: any) => handlePageChange(e.selected)}
          />
        )}
      </div>
    </div>
  );
}

export default ReviewModeration;
