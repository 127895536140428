import { useTranslation } from 'react-i18next';

import { Tooltip } from '@material-ui/core';

interface Props {
  onClick: () => void;
}

const CancelIconButton = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip placement="top" title={`${t('action.cancel')}`}>
      <button
        className="btn btn-sm btn-outline-secondary mx-1"
        type="button"
        onClick={onClick}
      >
        <i className="fas fa-undo" />
      </button>
    </Tooltip>
  );
};

export default CancelIconButton;
