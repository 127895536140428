import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


import { extractShopStaffList, fetchShopStaff } from '../../../../Redux/actions/shopActions/shopStaffActions';

import { AppButtonLink } from '../../../Common/Button';
import FormControlBox from '../../../Common/Form/FormControlBox';
import RatingStars from '../../../Common/RatingStars/RatingStars';
import Spinner from '../../../Common/Spinner/Spinner';
import { StaffAvatar } from '../../../Common/Staff';
import SectionToolbar from '../../../Common/Layout/SectionToolbar';


class Stylist extends Component {

  componentDidMount() {
    const { isShopOwner, user, match, shopId } = this.props;
    if (isShopOwner) {
      this.fetchStaffDataList(match.params.id || user.id);
    } else {
      this.fetchStaffDataList(shopId);
    }
  }

  sortStaff = ({ target: { value } }) => {
    const { isShopOwner, user, match, shopId } = this.props;
    if (isShopOwner) {
      this.fetchStaffDataList(match.params.id || user.id, value);
    } else {
      this.fetchStaffDataList(shopId, value);
    }
  };

  /**
   *
   * @param {number} id Shop Id
   * @param {string} order_by '' | 'asc' | 'desc'
   */
  fetchStaffDataList = (id, order_by = '') => {
    this.props.fetchShopStaff({ id, order_by });
  }

  render() {
    const {
      isShopOwner,
      user,
      isFetchingShopStaffDataList,
      staffDataList,
      match,
      t,
    } = this.props;

    return (
      <div
        className={`mt-4 ${
          isShopOwner && match.params.id === user.id ? '' : 'container'
        }`}
      >
        <div className="border p-3 bg-light">
          {isFetchingShopStaffDataList ? (
            <Spinner display="block" />
          ) : (
            <>
              <SectionToolbar
                heading={
                  <h3>
                    {t('shop.teamMember.labelWithCount', {
                      memberCount: staffDataList.length,
                    })}
                  </h3>
                }
              >
                <FormControlBox label={t('shopPublic.stylist.sortBy')}>
                  <select
                    className="form-item"
                    onChange={this.sortStaff}
                  >
                    <option value="desc">
                      {t('shopPublic.stylist.highRate')}
                    </option>
                    <option value="asc">
                      {t('shopPublic.stylist.lowRate')}
                    </option>
                  </select>
                </FormControlBox>
              </SectionToolbar>

              <div className="row">
                {staffDataList.map((staffData) => (
                  <div
                    className="col-md-6 col-lg-4 col-xl-3"
                    key={staffData.id}
                  >
                    <div className="mt-3 border bg-white py-4 px-3 text-center">
                      <div className="mx-auto" style={{ width: 80 }}>
                        <StaffAvatar src={staffData.avatar} />
                      </div>

                      <div className="py-3 stylist__name">{staffData.nickname}</div>

                      {staffData.strengths && (
                        <div className="pb-2 stylist__text">
                          {t('shopPublic.stylist.strengths', {
                            strengths: staffData.strengths,
                          })}
                          {/*
                          {staff.charge.map((item, index) => (
                            <span key={index}>
                              {item.name}
                              {index < staff.charge.length - 1 ? ', ' : ''}
                            </span>
                          ))}
*/}
                        </div>
                      )}

                      <RatingStars
                        rating={staffData.average_rating}
                        showNumeric
                      />

                      <AppButtonLink
                        className="mt-4"
                        theme="primary"
                        variant="outlined"
                        to={`/staff/${staffData.user_id}`}
                      >
                        {t('action.view')}
                      </AppButtonLink>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const user = state.auth.user;

  const { match, shopId } = props;

  let resolvedShopId;
  if (state.auth.isShop) {
    resolvedShopId = match.params.id || user.id;
  } else {
    resolvedShopId = shopId;
  }

  const {
    isFetchingShopStaffDataList,
    staffDataList,
  } = extractShopStaffList(state, resolvedShopId);

  return {
    isShopOwner: state.auth.isShop,
    user,
    isFetchingShopStaffDataList,
    staffDataList,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps,
      {
        fetchShopStaff,
      }
    )(Stylist)
  )
);
