import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Slider from 'react-slick';


import { BookingReview, Photo } from 'src/models/entity';

import { fetchNextPageOfShopReviews } from 'src/Redux/actions/features/shopReviewActions';

import { FormatDateTime } from 'src/Components/Common/DateTime';
import { Ellipsize } from 'src/Components/Common/Text';

import { CustomerBadge } from 'src/Components/Common/Customer';
import BookingReviewRatings from 'src/Components/Common/RatingStars/BookingReviewRatings';
import PhotoViewer from 'src/Components/Common/PhotoViewer/PhotoViewer';
import { AppButton } from 'src/Components/Common/Button';
import Spinner from 'src/Components/Common/Spinner/Spinner';


interface ArrowButtonProps {
  onClick?: () => void;
}

function NextArrow({ onClick }: ArrowButtonProps) {
  return (
    <div className="mob-slider-control mob-slider-control--next">
      <button onClick={onClick}>
        <i className="fas fa-angle-right" aria-hidden="true" />
      </button>
    </div>
  );
}

function PrevArrow({ onClick }: ArrowButtonProps) {
  return (
    <div className="mob-slider-control">
      <button onClick={onClick}>
        <i className="fas fa-angle-left" aria-hidden="true" />
      </button>
    </div>
  );
}

interface Props {
  // from parent
  className?: string;
  shopId: string;

  // from mapStateToProps
  hasFetchedAllReviews: boolean;
  isFetchingNextPageOfReviews: boolean;
  reviews: BookingReview[];

  // redux action
  fetchNextPageOfShopReviews: (
    shopId: string,
    since: string,
    skipping: string
  ) => void;
}

function ShopMainReviewSlider({
  className = '',
  shopId,

  hasFetchedAllReviews,
  isFetchingNextPageOfReviews,
  reviews,
  fetchNextPageOfShopReviews,
}: Props) {
  const { t } = useTranslation();

  const [isPhotoViewerOpened, setIsPhotoViewerOpened] = useState(false);
  const [viewablePhotos, setViewablePhotos] = useState<Photo[]>([]);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      swipeToSlide: true,
      adaptiveHeight: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }),
    []
  );

  const getNextPageOfReviews = () => {
    const lastReview = reviews[reviews.length - 1];

    fetchNextPageOfShopReviews(shopId, lastReview.created_at, lastReview.id);
  };

  const openPhotoViewer = (reviewIdx: number, photoIdx: number) => {
    setIsPhotoViewerOpened(true);
    setViewablePhotos(reviews[reviewIdx].photos || []);
    setActivePhotoIndex(photoIdx);
  };

  const closePhotoViewer = () => {
    setIsPhotoViewerOpened(false);
    setViewablePhotos([]);
    setActivePhotoIndex(0);
  };

  return (
    <>
      {isPhotoViewerOpened && (
        <PhotoViewer
          photos={viewablePhotos}
          initialIndex={activePhotoIndex}
          onClose={closePhotoViewer}
        />
      )}

      <div className={`shop-group ${className}`}>
        <div className="d-flex align-item-center justify-content-between shop-group__panel">
          <p className="theme-subtitle">
            <Link to="./review">{t('shopPublic.overview.latestReviews')}</Link>
          </p>
        </div>

        <div className="mt-3 mt-lg-0 shop-mob-review">
          <Slider {...sliderSettings}>
            {reviews.map((review, reviewIdx) => (
              <div
                key={review.id}
                className="mt-0 mt-lg-4 p-2 p-lg-3 review-container"
              >
                <div className="d-flex align-items-center mb-2 shop-review-header">
                  {review.customer && (
                    <CustomerBadge
                      avatarSize="small"
                      customer={review.customer}
                      horizontal
                    />
                  )}
                  <div className="flex-fill">
                    <p className="m-0 text-right review-info">
                      <FormatDateTime value={review.created_at} />
                    </p>
                  </div>
                </div>
                <Link to={`./review/${review.id}`} className="review-title">
                  {review.title}
                </Link>
                <div className="mt-1 review-content">
                  <p>
                    <Ellipsize text={review.review} maxlength={110} />
                  </p>
                  <Link to={`./review/${review.id}`} className="more-link">
                    {t('shopPublic.product.details')}
                  </Link>
                </div>
                <div className="mt-3">
                  <div className="row less-gutter-row">
                    {review.photos?.slice(0, 3).map((photo, photoIdx) => (
                      <div className="col-4" key={photo.id}>
                        <div className="news-prev-img">
                          <img
                            alt=""
                            src={photo.url}
                            style={{ cursor: 'pointer' }}
                            onClick={() => openPhotoViewer(reviewIdx, photoIdx)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <BookingReviewRatings ratings={review.review_average_rating} />
              </div>
            ))}

            {reviews.length > 0 && !hasFetchedAllReviews && (
              <div className="mt-0 mt-lg-4 px-2 py-5 p-lg-3 review-container">
                {isFetchingNextPageOfReviews ? (
                  <Spinner display="block" />
                ) : (
                  // Anchor to fetch more items
                  <AppButton
                    theme="primary"
                    size="block"
                    onClick={getNextPageOfReviews}
                  >
                    {t('shopPublic.overview.more')}
                  </AppButton>
                )}
              </div>
            )}
          </Slider>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    hasFetchedAllReviews: state.shopReviewReducer.hasFetchedAllReviews,
    isFetchingNextPageOfReviews: state.shopReviewReducer.isFetchingNextPage,
    reviews: state.shopReviewReducer.reviews || [],
  };
}

export default connect(mapStateToProps, {
  fetchNextPageOfShopReviews,
})(ShopMainReviewSlider);
