import { BookingReview } from 'src/models/entity';

import { GetResponse } from 'src/models/api/response/GetResponse';
import { ListResponse } from 'src/models/api/response/ListResponse';

import { apiGet } from '../api-utils';


/**
 * Retrieve a paginated list of *published* booking reviews served by a staff
 * @param staffId
 * @param limit
 * @param since Expected a date-time string
 * @param skipping Expected a Booking Review ID
 */
export const getStaffPublishedReviews = (
  staffId: string,
  limit?: number,
  since?: string,
  skipping?: string
) =>
  apiGet<ListResponse<BookingReview>>(`staff/${staffId}/reviews`, {
    limit,
    since,
    skipping,
  }).then((response) => response.data);

/**
 * Retrieve a piece of *published* booking review served by a staff
 * @param staffId
 * @param bookingId
 * @returns
 */
export const getPublishedReview = (staffId: string, bookingId: string) =>
  apiGet<GetResponse<BookingReview>>(`staff/${staffId}/review/${bookingId}`).then(
    (response) => response.data
  );
